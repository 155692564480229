<template>
    <v-container v-if="$store.state.user">

        <!-- Page header -->
        <v-row class="text-center mt-10">
            <v-col class="mb-4">
                <h1 class="display-2 font-weight-bold mb-3">View People</h1>
            </v-col> 
        </v-row>
        
        <!-- Show while people.data is being fetched -->
        <v-row v-if="!people.data" class="justify-center mt-10">
            <h3>Loading...</h3>
        </v-row>

        <!-- Beginning of expansion cards -->
        <template v-if="people.data">
            <v-row class="justify-center mb-10">
                <v-expansion-panels popout>
                    <v-expansion-panel v-for="(item, index) in people.data.rows" :key="index">

                        <!-- Name of person (card header) -->
                        <!-- Conditionals required to account for a lack of first or last name (remove comma separator) -->
                        <div v-if="!item.last_name">
                            <v-expansion-panel-header>
                                {{item.first_name}} 
                            </v-expansion-panel-header>
                        </div>
                        <div v-else-if="!item.first_name">
                            <v-expansion-panel-header>
                                {{item.last_name}}
                            </v-expansion-panel-header>                           
                        </div>
                        <div v-else>
                            <v-expansion-panel-header>
                                {{item.last_name}}, 
                                {{item.first_name}}
                            </v-expansion-panel-header>                           
                        </div> 

                        <!-- Content within expansion card (visible when card is selected) -->
                        <v-expansion-panel-content>
                            <!-- Commented out since data was inaccurate -->
                            <!-- If this data is needed, we will need to recreate code from personDetails view's detailed ledger -->
                            <!-- <br>
                            Interest: {{item.interest}} <br>
                            Fees: {{item.fees}} <br>
                            Principle: {{item.principle}} <br>
                            Total: {{item.total}}
                            <br> -->


                            <!-- Edit Button -->
                            <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                            @click="moveToPage(item.id, '/associations/personEdit')">Edit</v-btn>


                            <!-- Details button -->
                            <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                            @click="moveToPage(item.id, '/associations/peopleDetails')">Details</v-btn>


                            <!-- 'Add Assessment' button -->
                            <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                            @click="moveToPage(item.id, '/associations/addAssessment')">Add Assessment</v-btn>


                            <!-- 'Add Payment' button -->
                            <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                            @click="moveToPage(item.id, '/associations/addPayment')">Add Payment</v-btn>


                            <!-- 'Add Reoccurring' button -->
                            <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                            @click="moveToPage(item.id, '/associations/addReoccurring')">Add Reoccurring</v-btn>

                            <!-- Delete button and corresponding modal -->
                            <v-dialog transition="dialog-bottom-transition" max-width="600" persistent>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn elevation="2" outlined color="error" class="mt-5 mr-4"
                                    v-bind="attrs" v-on="on">Delete
                                    </v-btn>
                                </template>

                                <template v-slot:default="dialog">
                                    <v-card>
                                        <v-toolbar color="#4682b4" dark></v-toolbar>

                                        <!-- Message within modal -->
                                        <v-card-text>
                                            <div class="text-p pa-12">
                                                Are you sure you want to delete this record?
                                                This can't be undone.
                                            </div>
                                        </v-card-text>

                                        <!-- Buttons within modal -->
                                        <!-- *TODO* : Link YES button to delete action when clicked-->
                                        <v-card-actions class="justify-center">
                                            <v-btn text @click="deletePerson(item.id, item.first_name, item.last_name)">Yes</v-btn>
                                            <v-btn text @click="dialog.value = false">Cancel</v-btn>
                                        </v-card-actions>
                                        
                                    </v-card>
                                </template>
                            </v-dialog>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
        </template>
    </v-container>
</template>
 
<script>
    import PeopleService from '../../services/PeopleService';

    export default {
        name: 'archivedAssociationsView',
        data () {
            return {
                people: [],             // Array to hold results (all people) after API hit
                selectedPeople: [],     // Array to hold results (all associations) after API hit
                page: 1,                // Variable here for future pagination feature
                dialog: false,      
                associationID: null,    // Placeholder for association id retrieved below from vuex store
                notes: "",
                interest: null,
                amount: 0.00,
            }
        },

        async mounted () {
            // Set association id on this page to the stored state (set via button click from associations page)
            this.associationID = this.$store.state.route.params.association_id

            // Get all associations from back-end
            this.people = await PeopleService.getArchivedPeopleByAssociation({association_id: this.associationID})
        },

        methods: {

            // Used in @click on buttons to pass user ID and route of desired page
            moveToPage(id, route) {
                if (route == '/associations/peopleDetails') {
                    this.$router.push({ path: `${route}/${id}`})
                } else if (route == '/associations/personEdit') {
                    this.$router.push({ path: `${route}/association_id=${this.associationID}&person_id=${id}`})
                }
                else{
                    this.$store.dispatch('setPersonID', id)
                    this.$router.push({ path: route})
                }
            },

            setArchived() {
                console.log("Under Development -- Will toggle bool var in DB and remove from non-archived view screens via conditionals.");
            },

            async deletePerson(person_id, person_first_name, person_last_name) {

                await PeopleService.deletePerson({
                    id: person_id,
                    first_name: person_first_name,
                    last_name: person_last_name
                })

                // Move to the associations edit page (bringing clicked association id)
                this.$router.push({ path: '/associations/associations'})
            }
        }
    }
</script>